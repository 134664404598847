import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import DefaultLayout from "./layout/DefaultLayout";
import LoginLayout from "./layout/LoginLayout";
import withResources from "./HOCs/withResources";

const App = () => {
  return (
    <Suspense fallback={"..."}>
      <Switch>
        <Route exact path="/login" component={LoginLayout} />
        <Route path="/" component={DefaultLayout} />
      </Switch>
      <Suspense />
    </Suspense>
  );
};

export default withResources(App);
