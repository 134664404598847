import produce from "immer";
import { setLanguage, getLanguage } from "../../utils/l10n";

export const settings = {
  state: {
    currentLanguage: getLanguage()
  },
  reducers: {
    setCurrentLanguage(state, payload) {
      setLanguage(payload);
      return produce(state, draft => {
        draft.currentLanguage = payload;
      });
    }
  },
  effects: () => ({})
};
