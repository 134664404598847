import React, { useState } from "react";
import styled from "styled-components";
import { Card, Icon } from "tabler-react";
import { STATUSES } from "../constants";
import { useTranslation } from "react-i18next";

const HIDE_TIMEOUT = 3000;

const StyledCardAlert = styled(Card.Alert)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props =>
    props.fixed &&
    `
    position: fixed;
    right: 15px;
    bottom: 15px;
    max-width: 33%;
  `}
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export const SuccessCardAlert = ({
  onHide,
  text,
  autoCanelDisabled,
  fixed
}) => {
  const { t } = useTranslation();

  if (!autoCanelDisabled) setTimeout(onHide, HIDE_TIMEOUT);
  return (
    <StyledCardAlert color="success" fixed={fixed}>
      {text ? text : t("Operation successed")}
      <StyledIcon name="x" onClick={onHide} />
    </StyledCardAlert>
  );
};

export const FailureCardAlert = ({
  onHide,
  text,
  autoCanelDisabled,
  fixed
}) => {
  const { t } = useTranslation();

  if (!autoCanelDisabled) setTimeout(onHide, HIDE_TIMEOUT);
  return (
    <StyledCardAlert color="danger" fixed={fixed}>
      {text ? text : t("Error has occured")}
      <StyledIcon name="x" onClick={onHide} />
    </StyledCardAlert>
  );
};

export const useAlert = initialParams => {
  const [params, setParams] = useState(initialParams);
  const [status, setStatus] = useState(null);

  const setNoStatus = () => {
    setStatus(null);
    setParams(initialParams || null);
  };
  const setSuccessStatus = _params => {
    setStatus(STATUSES.SUCCESS);
    setParams({ ...params, ..._params });
  };
  const setFailureStatus = _params => {
    setStatus(STATUSES.FAIL);
    setParams({ ...params, ..._params });
  };

  return [status, setNoStatus, setSuccessStatus, setFailureStatus, params];
};

const CardStatusAlert = ({
  status,
  onHide,
  failText,
  autoCanelDisabled,
  fixed
}) => {
  if (!status) return null;
  if (status === STATUSES.SUCCESS)
    return (
      <SuccessCardAlert onHide={onHide} autoCanelDisabled={autoCanelDisabled} />
    );
  else if (status === STATUSES.FAIL)
    return (
      <FailureCardAlert
        onHide={onHide}
        text={failText || null}
        autoCanelDisabled={autoCanelDisabled}
        fixed={fixed}
      />
    );
};
export default CardStatusAlert;
