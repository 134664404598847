import React from "react";
import styled from "styled-components";

import { NavLink } from "react-router-dom";
import { Icon, Container, Grid } from "tabler-react";
import { navigationRoutes } from "../../config/routes";
import { useTranslation } from "react-i18next";

const NavLinkWrapper = styled(NavLink)`
  padding: 1em !important;
`;

const items = navigationRoutes.map(route => {
  return {
    value: route.name,
    to: route.path,
    useExact: route.exact,
    icon: route.icon
  };
});

const DesktopNavigation = () => {
  const { t } = useTranslation();

  return (
    <div className="header d-lg-flex p-0">
      <Container>
        <Grid.Row>
          <Grid.Col className="col-lg order-lg-first">
            <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
              {items.filter(Boolean).map((item, i) => {
                return (
                  <li key={i} className="nav-item">
                    <NavLinkWrapper to={item.to} className="nav-link">
                      <Icon name={item.icon} />
                      {t(item.value)}
                    </NavLinkWrapper>
                  </li>
                );
              })}
            </ul>
          </Grid.Col>
        </Grid.Row>
      </Container>
    </div>
  );
};

export default DesktopNavigation;
