import React from "react";
import styled from "styled-components";
import ReactFlagsSelect from "react-flags-select";
import i18n from "i18next";
import { Container, Grid } from "tabler-react";
import withCurrentLanguage from "../../HOCs/withCurrentLanguage";
import {
  getLanguageCountryByCode,
  getCountryByLanguageCode
} from "../../utils/l10n";
import logo from "../../assets/images/logo.png";

const HeaderContainer = styled.div`
  position: relative;
  height: 64px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 40, 100, 0.12);
`;
const Logo = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
`;
const Info = styled.div`
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  
`;
const InfoName = styled.p`
  margin-bottom: 0;
  font-size: 14px;
`;
const InfoPageName = styled.p`
  margin-bottom: 0;
  font-size: 12px;
  color: #aeaeae;
`;
const SettingsHeader = styled.div`
  position: absolute;
  top: 16px;
  right: 8px;
  .country-label,
  .arrow-down {
    color: #aaa;
  }
  .flag-select {
    margin-right: 10px;
  }
  .flag-select img {
    top: 0 !important;
  }
`;

const Header = ({ currentLanguage, setCurrentLanguage }) => {
  const onSelectLanguage = countryCode => {
    const languageCode = getLanguageCountryByCode(countryCode);

    setCurrentLanguage(languageCode);
    i18n.changeLanguage(languageCode);
  };
  return (
    <HeaderContainer>
      <Container>
        <Grid.Row>
          <Grid.Col md={2}>
            <Logo>
              <img src={logo} alt="" width="130px;" />
            </Logo>
          </Grid.Col>
          <Grid.Col md={3} offset={7}>
            <Info>
              <InfoName>Małgorzata Księżarczyk</InfoName>
              <InfoPageName>Koci Eden *PL</InfoPageName>
            </Info>
            <SettingsHeader>
              <ReactFlagsSelect
                countries={["GB", "PL"]}
                customLabels={{ GB: "English", PL: "Polski" }}
                defaultCountry={getCountryByLanguageCode(currentLanguage)}
                onSelect={onSelectLanguage}
              />
            </SettingsHeader>
          </Grid.Col>
        </Grid.Row>
      </Container>
    </HeaderContainer>
  );
};

export default withCurrentLanguage(Header);
