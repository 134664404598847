import styled from "styled-components";

export const GlobalStyle = styled.div`
  body {
    margin: 0;
    color: #4f1536;
    font-family: Arial, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .dimmer.active .dimmer-content {
    opacity: 0.5 !important;
  }
  .container {
    @media (min-width: 1366px) {
      max-width: 1100px !important;
    }
    @media (min-width: 1400px) {
      max-width: 1200px !important;
    }
    @media (min-width: 1600px) {
      max-width: 1300px !important;
    }
  }
  .ReactModal__Overlay {
    z-index: 1050 !important;
  }
`;
