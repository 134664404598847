import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-flags-select/css/react-flags-select.css";
import "tabler-react/dist/Tabler.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./config/i18n";
import "./config/offlinePlugin";

import { getInitialData } from "./config/getInitialData";
import { GlobalStyle } from "./config/GlobalStyle";

import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./services/store";
import history from "./services/history";
import App from "./App";

const NEED_INITIAL_DATA = false;
const bootstrap = async () => {
  const rootElement = document.getElementById("root");

  if (NEED_INITIAL_DATA) {
    const { info } = await getInitialData();
    if (!info) ReactDOM.render(<div>SOMETHING WENT WRONG</div>, rootElement);
  }

  ReactDOM.render(
    <Provider store={store}>
      <GlobalStyle>
        <Router history={history}>
          <App />
        </Router>
      </GlobalStyle>
    </Provider>,
    rootElement
  );
};

bootstrap();
