import { useEffect } from "react";
import { TOKEN_INFO, MIN_IN_MS } from "../constants";
import AuthApi from "../services/auth/api";

export const isLoggedIn = () => {
  const tokenInfo = JSON.parse(localStorage.getItem(TOKEN_INFO));
  if (!tokenInfo) return false;
  const expiryDate = new Date(Number(tokenInfo.exp) * 1000);
  if (expiryDate < new Date()) return false;
  return true;
};
export const setLoginToken = token => {
  const tokenDetails = decodeToken(token);
  tokenDetails.token = token;
  localStorage.setItem(TOKEN_INFO, JSON.stringify(tokenDetails));
};
export const getToken = () => {
  const tokenInfo = JSON.parse(localStorage.getItem(TOKEN_INFO));
  if (!tokenInfo) return null;
  return tokenInfo.token;
};
export const decodeToken = token => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(window.atob(base64));
  } catch (error) {
    return null;
  }
};
export const useRefreshToken = () => {
  const refreshToken = async () => {
    try {
      await AuthApi.refreshToken();
    } catch (e) {
      import("../services/store").then(store => {
        store.default.dispatch.auth.logout();
      });
    }
  };
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      refreshToken();
    }, 2 * MIN_IN_MS);
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);
};
