import React, { PureComponent } from "react";
import { connect } from "react-redux";

const withAuth = WrappedComponent => {
  class WithAuth extends PureComponent {
    async componentDidMount() {
      const { history, isLoggedIn } = this.props;
      if (!isLoggedIn) history.push("/login");
    }
    render() {
      if (!this.props.isLoggedIn) return null;
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(state => ({
    isLoggedIn: state.auth.isLoggedIn
  }))(WithAuth);
};

export default withAuth;
