import React, { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import routes from '../config/routes'
import LoadingError from '../components/LoadingError'
import Header from './defaultLayout/Header'
import Navigation from './defaultLayout/Navigation'
import withAuth from '../HOCs/withAuth'
import { useRefreshToken } from '../utils/auth'
import withAuthorizedResources from '../HOCs/withAuthorizedResources'

const DefaultLayout = props => {
    useRefreshToken()

    return (
        <LoadingError>
            <Header />
            <Suspense fallback={'...'}>
                <Navigation />
            </Suspense>
            <Suspense fallback={'...'}>
                <Switch>
                    {routes.map((route, idx) => {
                        const RouteComponent = lazy(route.loader)
                        return (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => <RouteComponent {...props} route={route} />}
                            />
                        )
                    })}
                    <Redirect to="/cats/all" />
                </Switch>
            </Suspense>
        </LoadingError>
    )
}

export default withAuth(withAuthorizedResources(DefaultLayout))
