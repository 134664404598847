import produce from "immer";
import ResourcesApi from "./api";

export const resources = {
  state: {
    countries: null,
    titles: null,
    colors: null,
    bloodTypes: null,
    testTypes: null,
    testResults: null,
    judges: null,
    grades: null,
    childStatuses: null,
    litterTypes: null,
    seasons: null,
    animals: {
      male: null,
      female: null
    }
  },
  reducers: {
    setCountries(state, payload) {
      return produce(state, draft => {
        draft.countries = payload;
      });
    },
    setTitles(state, payload) {
      return produce(state, draft => {
        draft.titles = payload;
      });
    },
    setColors(state, payload) {
      return produce(state, draft => {
        draft.colors = payload;
      });
    },
    setBloodTypes(state, payload) {
      return produce(state, draft => {
        draft.bloodTypes = payload;
      });
    },
    setTestTypes(state, payload) {
      return produce(state, draft => {
        draft.testTypes = payload;
      });
    },
    setTestResults(state, payload) {
      return produce(state, draft => {
        draft.testResults = payload;
      });
    },
    setJudges(state, payload) {
      return produce(state, draft => {
        draft.judges = payload;
      });
    },
    setGrades(state, payload) {
      return produce(state, draft => {
        draft.grades = payload;
      });
    },
    setMaleAnimals(state, payload) {
      return produce(state, draft => {
        draft.animals.male = payload;
      });
    },
    setFemaleAnimals(state, payload) {
      return produce(state, draft => {
        draft.animals.female = payload;
      });
    },
    setChildStatuses(state, payload) {
      return produce(state, draft => {
        draft.childStatuses = payload;
      });
    },
    setLitterTypes(state, payload) {
      return produce(state, draft => {
        draft.litterTypes = payload;
      });
    },
    setSeasons(state, payload) {
      return produce(state, draft => {
        draft.seasons = payload;
      });
    }
  },
  effects: () => ({
    async getCountries() {
      try {
        const countries = await ResourcesApi.getCountries();
        this.setCountries(countries);
      } catch (e) {
        console.error(e);
      }
    },
    async getTitles() {
      try {
        const titles = await ResourcesApi.getTitles();
        this.setTitles(titles);
      } catch (e) {
        console.error(e);
      }
    },
    async getColors() {
      try {
        const colors = await ResourcesApi.getColors();
        this.setColors(colors);
      } catch (e) {
        console.error(e);
      }
    },
    async getBloodTypes() {
      try {
        const bloodTypes = await ResourcesApi.getBloodTypes();
        this.setBloodTypes(bloodTypes);
      } catch (e) {
        console.error(e);
      }
    },
    async getTestTypes() {
      try {
        const testTypes = await ResourcesApi.getTestTypes();
        this.setTestTypes(testTypes);
      } catch (e) {
        console.error(e);
      }
    },
    async getTestResults() {
      try {
        const testResults = await ResourcesApi.getTestResults();
        this.setTestResults(testResults);
      } catch (e) {
        console.error(e);
      }
    },
    async getJudges() {
      try {
        const judges = await ResourcesApi.getJudges();
        this.setJudges(judges);
      } catch (e) {
        console.error(e);
      }
    },
    async getGrades() {
      try {
        const grades = await ResourcesApi.getGrades();
        this.setGrades(grades);
      } catch (e) {
        console.error(e);
      }
    },
    async getChildStatuses() {
      try {
        const childStatuses = await ResourcesApi.getChildStatuses();
        this.setChildStatuses(childStatuses);
      } catch (e) {
        console.error(e);
      }
    },
    async getLitterTypes() {
      try {
        const litterTypes = await ResourcesApi.getLitterTypes();
        this.setLitterTypes(litterTypes);
      } catch (e) {
        console.error(e);
      }
    },

    async getSeasons() {
      try {
        const seasons = await ResourcesApi.getSeasons();
        this.setSeasons(seasons);
      } catch (e) {
        console.error(e);
      }
    },
    async getAnimalsSummary() {
      try {
        const [males, females] = await Promise.all([
          ResourcesApi.getAnimalsSummary("m"),
          ResourcesApi.getAnimalsSummary("f")
        ]);

        this.setMaleAnimals(males);
        this.setFemaleAnimals(females);
      } catch (e) {
        console.error(e);
      }
    }
  })
};
