import { requestBase, requestWithAuth } from "../../utils/api";
import { cacheMiddleware } from "../middlewares";

const cachedRequest = requestBase.middlewares([
  cacheMiddleware(1000 * 60 * 60)
]);

class ResourcesApi {
  static async getCountries() {
    return await cachedRequest
      .url(`/Resources/countries`)
      .get()
      .json();
  }
  static async getTitles() {
    return await cachedRequest
      .url(`/Resources/titles`)
      .get()
      .json();
  }
  static async getColors() {
    return await cachedRequest
      .url(`/Resources/colors`)
      .get()
      .json();
  }
  static async getBloodTypes() {
    return await cachedRequest
      .url(`/Resources/bloodTypes`)
      .get()
      .json();
  }
  static async getTestTypes() {
    return await cachedRequest
      .url(`/Resources/testTypes`)
      .get()
      .json();
  }
  static async getTestResults() {
    return await cachedRequest
      .url(`/Resources/testResults`)
      .get()
      .json();
  }
  static async getJudges() {
    return await cachedRequest
      .url(`/Resources/judges`)
      .get()
      .json();
  }
  static async getGrades() {
    return await cachedRequest
      .url(`/Resources/grades`)
      .get()
      .json();
  }
  static async getChildStatuses() {
    return await cachedRequest
      .url(`/Resources/childStatuses`)
      .get()
      .json();
  }
  static async getLitterTypes() {
    return await cachedRequest
      .url(`/Resources/litterTypes`)
      .get()
      .json();
  }
  static async getSeasons() {
    return await cachedRequest
      .url(`/Resources/seasons`)
      .get()
      .json();
  }
  static async getAnimalsSummary(gender) {
    return await requestWithAuth
      .url(`/Animals/sumary/${gender}`)
      .get()
      .json();
  }
}

export default ResourcesApi;
