import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { Card, Form, Button, StandaloneFormPage } from "tabler-react";
import logo from "../assets/images/logo.png";
import { connect } from "react-redux";
import CardStatusAlert, { useAlert } from "../components/Alert";

const LoginLayoutPage = styled.div`
  > div {
    height: 100vh;
  }
`;

//kocieden@wp.pl : zaq0987
const LoginLayout = ({ isLoggedIn, logIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setNoStatus, setSuccessStatus, setFailureStatus] = useAlert();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const login = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await logIn({ email, password });
      setLoading(false);
      setSuccessStatus();
    } catch (e) {
      setLoading(false);
      setFailureStatus();
      setError(e.json.message);
    }
    setLoading(false);
  };

  if (isLoggedIn) return <Redirect to={`/`} />;
  return (
    <LoginLayoutPage>
      <StandaloneFormPage imageURL={logo}>
        <Card>
          <Card.Body>
            <Card.Title>{t("Login to your Account")}</Card.Title>
            <Form onSubmit={login}>
              <Form.Group>
                <Form.Label>{t("Email Address")}</Form.Label>
                <Form.Input
                  name="email"
                  class="form-control"
                  type="text"
                  placeholder={t("Enter email")}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t("Password")}</Form.Label>
                <Form.Input
                  name="password"
                  class="form-control"
                  type="password"
                  placeholder={t("Password")}
                  onChange={e => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                />
              </Form.Group>
              <Form.Footer>
                <Button block type="submit" color="primary" loading={isLoading}>
                  {t("Login")}
                </Button>
              </Form.Footer>
            </Form>
          </Card.Body>
        </Card>
        <CardStatusAlert
          status={status}
          onHide={setNoStatus}
          failText={error || null}
          fixed
        />
      </StandaloneFormPage>
    </LoginLayoutPage>
  );
};

export default connect(
  state => ({
    isLoggedIn: state.auth.isLoggedIn
  }),
  dispatch => ({
    logIn: dispatch.auth.logIn
  })
)(LoginLayout);
