/* A throttling cache. */
export const cacheMiddleware = (throttle = 0) => {
  const cache = new Map();
  const inflight = new Map();
  const throttling = new Set();

  return next => (url, opts) => {
    const key = opts.method + "@" + url;

    if (!opts.noCache && throttling.has(key)) {
      // If the cache contains a previous response and we are throttling, serve it and bypass the chain.
      if (cache.has(key)) return Promise.resolve(cache.get(key).clone());
      // If the request in already in-flight, wait until it is resolved
      else if (inflight.has(key)) {
        return new Promise((resolve, reject) => {
          inflight.get(key).push([resolve, reject]);
        });
      }
    }

    // Init. the pending promises Map
    if (!inflight.has(key)) inflight.set(key, []);

    // If we are not throttling, activate the throttle for X milliseconds
    if (throttle && !throttling.has(key)) {
      throttling.add(key);
      setTimeout(() => {
        throttling.delete(key);
      }, throttle);
    }

    // We call the next middleware in the chain.
    return next(url, opts)
      .then(_ => {
        // Add a cloned response to the cache
        cache.set(key, _.clone());
        // Resolve pending promises
        inflight.get(key).forEach(([resolve, reject]) => resolve(_.clone()));
        // Remove the inflight pending promises
        inflight.delete(key);
        // Return the original response
        return _;
      })
      .catch(_ => {
        // Reject pending promises on error
        inflight.get(key).forEach(([resolve, reject]) => reject(_));
        inflight.delete(key);
        throw _;
      });
  };
};
