import produce from "immer";
import AuthApi from "./api";
import { setLoginToken, isLoggedIn } from "../../utils/auth";
import history from "../history";

export const auth = {
  state: {
    isLoggedIn: isLoggedIn()
  },
  reducers: {
    setUserLoggedIn(state, payload) {
      return produce(state, draft => {
        draft.isLoggedIn = payload;
      });
    }
  },
  effects: dispatch => ({
    async logIn({ email, password }) {
      try {
        const response = await AuthApi.logIn(email, password);
        setLoginToken(response.token);
        this.setUserLoggedIn(true);
        history.push(`/`);
        return true;
      } catch (error) {
        console.error(error);
        this.setUserLoggedIn(false);
        throw error;
      }
    },
    async logout() {
      this.setUserLoggedIn(false);
      await AuthApi.logout();
    }
  })
};
