export const navigationRoutes = [
    // {
    //   name: "Home",
    //   icon: "home",
    //   path: "/home",
    //   loader: () => import("../views/HomePage")
    // },
    {
        name: 'Cats',
        icon: 'box',
        path: '/cats',
        loader: () => import('../components/RouterPage'),
        children: [
            {
                name: 'All',
                path: '/all',
                loader: () => import('../views/catsPage/AllCats'),
            },
            {
                name: 'Females',
                path: '/females',
                loader: () => import('../views/catsPage/Females'),
            },
            {
                name: 'Males',
                path: '/males',
                loader: () => import('../views/catsPage/Males'),
            },
            {
                name: 'Neuters',
                path: '/neuters',
                loader: () => import('../views/catsPage/Neuters'),
            },
        ],
    },
    {
        name: 'Litters',
        icon: 'calendar',
        path: '/litters',
        loader: () => import('../components/RouterPage'),
        children: [
            {
                name: 'Current',
                path: '/current',
                loader: () => import('../views/littersPage/CurrentLitters'),
            },
            {
                name: 'Planned',
                path: '/planned',
                loader: () => import('../views/littersPage/PlannedLitters'),
            },
            {
                name: 'Previous',
                path: '/previous',
                loader: () => import('../views/littersPage/PreviousLitters'),
            },
        ],
    },
    {
        name: 'Newses',
        icon: 'file',
        path: '/newses',
        loader: () => import('../views/NewsesPage'),
    },
    {
        name: 'Static pages',
        icon: 'check-square',
        path: '/static-pages',
        loader: () => import('../views/StaticPagesPage'),
    },
]

const routes = [
    /* Exact routes must be at beginning (some routes have wrapper for children with same starting path)*/
    {
        name: 'Add cat',
        path: '/cats/add',
        exact: true,
        loader: () => import('../views/catsPage/cats/AddCat'),
    },
    {
        name: 'Edit cat',
        path: '/cats/edit/:id',
        exact: true,
        loader: () => import('../views/catsPage/cats/EditCat'),
    },
    {
        name: 'Add litter',
        path: '/litters/add',
        exact: true,
        loader: () => import('../views/littersPage/litters/AddLitter'),
    },
    {
        name: 'Edit litter',
        path: '/litters/edit/:id',
        exact: true,
        loader: () => import('../views/littersPage/litters/EditLitter'),
    },
    {
        name: 'Edit news',
        path: '/newses/(add|edit)/:id?',
        exact: true,
        loader: () => import('../views/newsesPage/EditNews'),
    },
    ...navigationRoutes,
]
export default routes
