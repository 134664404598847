import {
  LANGUAGE,
  LANGUAGE_CODES,
  LOCALIZES_BY_LANGUAGE,
  COUNTRIES_BY_LANGUAGE
} from "../constants";

export const getLanguage = () => {
  let language = localStorage.getItem(LANGUAGE);
  let detected = navigator.language;

  detected = detected.split("-");
  detected =
    Object.values(LANGUAGE_CODES).find(ls => ls === detected[0]) ||
    LANGUAGE_CODES.EN;

  language = localStorage.getItem(LANGUAGE)
    ? localStorage.getItem(LANGUAGE)
    : detected;
  localStorage.setItem(LANGUAGE, language);

  return language;
};

export const setLanguage = lang => {
  let language = lang.toLowerCase();
  if (Object.values(LANGUAGE_CODES).some(ls => ls === language))
    localStorage.setItem(LANGUAGE, language);
};

export const getLanguageCountryByCode = countryCode => {
  const currentCountry = Object.entries(COUNTRIES_BY_LANGUAGE).find(
    ([_, value]) => value === countryCode
  );
  return currentCountry
    ? currentCountry[0]
    : Object.keys(COUNTRIES_BY_LANGUAGE)[0];
};

export const getCountryByLanguageCode = languageCode => {
  const currentLanguage = Object.entries(COUNTRIES_BY_LANGUAGE).find(
    ([key, _]) => key === languageCode
  );
  return currentLanguage
    ? currentLanguage[1]
    : Object.keys(COUNTRIES_BY_LANGUAGE)[1];
};

export const getLocaleByLanguage = () => {
  const language = getLanguage();
  const currentLocale = Object.entries(LOCALIZES_BY_LANGUAGE).find(
    ([key, _]) => key === language
  );
  return currentLocale || LOCALIZES_BY_LANGUAGE.en;
};
