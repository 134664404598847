import history from "../history";
import { requestBase, requestWithAuth } from "../../utils/api";
import { setLoginToken } from "../../utils/auth";

class AuthApi {
  static async logIn(email, password) {
    const userData = await requestBase
      .errorType("json")
      .url("/auth/token")
      .post({ email, password })
      .json();
    return userData;
  }

  static logout() {
    localStorage.clear();
    history.push("/login");
  }

  static async refreshToken() {
    await requestWithAuth
      .url(`/auth/token/refresh`)
      .post()
      .unauthorized(() => {
        throw new Error("Token has expired");
      })
      .json(res => {
        setLoginToken(res.token);
      });
  }
}

export default AuthApi;
