import wretch from 'wretch'
import { fetch } from 'whatwg-fetch'
import { getToken } from './auth'

export const requestBase = wretch()
    .errorType('json')
    .polyfills({
        fetch: fetch,
    })
    .url(process.env.REACT_APP_API)
    .defer((w, url, options) => {
        if (options.context) {
            const { lang } = options.context
            return w.query({ lang })
        }
        return w
    })
    .content(`application/json`)

export const requestWithAuth = wretch()
    .errorType('json')
    .polyfills({
        fetch: fetch,
    })
    .url(process.env.REACT_APP_API)
    .defer(w => {
        let query = {}
        return w.query(query).auth(`Bearer ${getToken()}`)
    })
    .content(`application/json`)
