import React, { PureComponent } from "react";
import { connect } from "react-redux";

const withAuthorizedResources = WrappedComponent => {
  class WithAuthorizedResources extends PureComponent {
    async componentDidMount() {
      const { animals, getAnimals } = this.props;
      if (!animals.male || !animals.female) getAnimals();
    }

    render() {
      const { animals } = this.props;

      if (!animals.male || !animals.female) return null;
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(
    state => ({
      animals: state.resources.animals
    }),
    dispatch => ({
      getAnimals: dispatch.resources.getAnimalsSummary
    })
  )(WithAuthorizedResources);
};

export default withAuthorizedResources;
