import React, { PureComponent } from "react";
import styled from "styled-components";

const ErrorWrapper = styled.div`
  min-height: calc(100vh - 276px);
  color: #9b2e68 !important;
  font-family: Clicker;
`;
const PageName = styled.div`
  font-size: 26px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  vertical-align: center;
  background-color: #ffc4e3;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
`;
const PageErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  p {
    width: 100%;
    text-align: right;
  }
`;
class LoadingError extends PureComponent {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorWrapper>
          <PageName>pageName</PageName>
          <PageErrorContent>
            <h5>{"unknown error"}</h5>
          </PageErrorContent>
        </ErrorWrapper>
      );
    }

    return this.props.children;
  }
}

export default LoadingError;
