import { init } from "@rematch/core";
import createLoadingPlugin from "@rematch/loading";
import { auth } from "./auth/model";
import { settings } from "./settings/model";
import { resources } from "./resources/model";

const loadingPlugin = createLoadingPlugin({ asNumber: true });

const store = init({
  plugins: [loadingPlugin],
  models: {
    auth,
    settings,
    resources
  }
});

export default store;
