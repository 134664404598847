import React, { PureComponent } from "react";
import { connect } from "react-redux";

const withResources = WrappedComponent => {
  class WithResources extends PureComponent {
    async componentDidMount() {
      const {
        countries,
        getCountries,
        titles,
        getTitles,
        colors,
        getColors,
        bloodTypes,
        getBloodTypes,
        testTypes,
        getTestTypes,
        testResults,
        getTestResults,
        judges,
        getJudges,
        grades,
        getGrades,
        childStatuses,
        getChildStatuses,
        seasons,
        getSeasons,
        litterTypes,
        getLitterTypes
      } = this.props;

      if (!countries) getCountries();
      if (!titles) getTitles();
      if (!colors) getColors();
      if (!bloodTypes) getBloodTypes();
      if (!testTypes) getTestTypes();
      if (!testResults) getTestResults();
      if (!judges) getJudges();
      if (!grades) getGrades();
      if (!childStatuses) getChildStatuses();
      if (!seasons) getSeasons();
      if (!litterTypes) getLitterTypes();
    }

    render() {
      const {
        countries,
        titles,
        colors,
        bloodTypes,
        testTypes,
        testResults,
        judges,
        grades,
        childStatuses,
        seasons,
        litterTypes
      } = this.props;

      //TODO: remove that condition and check needed values at pages/components (maybe fix in selectors will be compulsory)
      if (
        !countries ||
        !titles ||
        !colors ||
        !bloodTypes ||
        !testTypes ||
        !testResults ||
        !judges ||
        !grades ||
        !childStatuses ||
        !seasons ||
        !litterTypes
      ) {
        return null;
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(
    state => ({
      countries: state.resources.countries,
      titles: state.resources.titles,
      colors: state.resources.colors,
      bloodTypes: state.resources.bloodTypes,
      testTypes: state.resources.testTypes,
      testResults: state.resources.testResults,
      judges: state.resources.judges,
      grades: state.resources.grades,
      childStatuses: state.resources.childStatuses,
      litterTypes: state.resources.litterTypes,
      seasons: state.resources.seasons
    }),
    dispatch => ({
      getCountries: dispatch.resources.getCountries,
      getTitles: dispatch.resources.getTitles,
      getColors: dispatch.resources.getColors,
      getBloodTypes: dispatch.resources.getBloodTypes,
      getTestTypes: dispatch.resources.getTestTypes,
      getTestResults: dispatch.resources.getTestResults,
      getJudges: dispatch.resources.getJudges,
      getGrades: dispatch.resources.getGrades,
      getChildStatuses: dispatch.resources.getChildStatuses,
      getLitterTypes: dispatch.resources.getLitterTypes,
      getSeasons: dispatch.resources.getSeasons
    })
  )(WithResources);
};

export default withResources;
