export const INFO = "INFO";
export const DOMAIN_DEBUG = "DOMAIN_DEBUG";
export const LANGUAGE = "LANGUAGE";
export const TOKEN_INFO = "TOKEN_INFO";

export const LANGUAGE_CODES = {
  EN: "en",
  PL: "pl"
};
export const COUNTRIES_BY_LANGUAGE = {
  en: "GB",
  pl: "PL"
};
export const LOCALIZES_BY_LANGUAGE = {
  en: "en-GB",
  pl: "pl-PL"
};

export const UI_BREAKPOINTS = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1280
};

export const STATUSES = {
  SUCCESS: "SUCCESS",
  FAIL: "FAIL"
};

export const NOT_IMPLEMENTED_TEXT = "NOT IMPLEMENTED";

export const RACE_TYPE = {
  BRITISH_SHORTHAIR: {
    id: 1,
    name: "British Shorthair"
  },
  RAGDOLL: {
    id: 2,
    name: "Ragdoll"
  }
};

export const LITTER_TYPES = {
  PREVIOUS: "Previous",
  CURRENT: "Current",
  PLANNED: "Planned"
};
export const PLANNED_LITTER_TYPE_ID = 3;
export const MIN_IN_MS = 1000 * 60;

export const AGE_TYPES = {
  WEEKS: {
    id: 1,
    name: "weeks"
  },
  YEARS: {
    id: 2,
    name: "years"
  },
  MONTHS: {
    id: 3,
    name: "months"
  }
};
